
import * as type from '../../types/history'
import moment from 'moment'

const reducer = (state = {
    lastUpdate: moment().toISOString(),
    transactions: []
}, action) => {
    switch (action.type) {
        case type.FETCH_PENDING_PURCHASE_HISTORY:
            return action.payload
        default:
            return state
    }
}

export default reducer