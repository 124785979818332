//COMPLETED
export const FETCH_PURCHASE_HISTORY = "FETCH_PURCHASE_HISTORY"
export const CLEAR_PURCHASE_HISTORY = "CLEAR_PURCHASE_HISTORY"
export const SWITCH_PAGE_PURCHASE_HISTORY = "SWITCH_PAGE_PURCHASE_HISTORY"
export const FAKE_UPDATE_TRANSACTION = "FAKE_UPDATE_TRANSACTION"

//SEARCH
export const FETCH_PURCHASE_HISTORY_SEARCH = "FETCH_PURCHASE_HISTORY_SEARCH"
export const CLEAR_PURCHASE_HISTORY_SEARCH = "CLEAR_PURCHASE_HISTORY_SEARCH"
export const SWITCH_PAGE_PURCHASE_HISTORY_SEARCH = "SWITCH_PAGE_PURCHASE_HISTORY_SEARCH"
export const FAKE_UPDATE_TRANSACTION_SEARCH = "FAKE_UPDATE_TRANSACTION_SEARCH"

//PENDING
export const FETCH_PENDING_PURCHASE_HISTORY = "FETCH_PENDING_PURCHASE_HISTORY"
//PAGE
export const SET_HISTORY_TAB = "SET_HISTORY_TAB"
export const SET_HISTORY_BRANCH = "SET_HISTORY_BRANCH"
export const CLEAR_HISTORY_BRANCH = "CLEAR_HISTORY_BRANCH"




