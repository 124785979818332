import randomString from "random-string";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import * as type from "../types/promotion";
import moment from "moment";
import async from "async";

export function addPromotion(data, cb) {
  return (dispatch, getState) => {
    const { user, shop } = getState();
    let shopId = user.loggedIn.shopId;
    const link = shop.detail.link;

    if (link && link.inventory) {
      shopId = link.inventory;
    }
    let randomUID = randomString({
      length: 8,
      numeric: true,
      letters: true,
      special: false
    });

    let params = {
      TableName: "Promotions",
      Item: {
        hqId: shopId,
        uid: randomUID,
        row: moment().valueOf(),
        name: data.name,
        uidHolder: data.uidHolder,
        printDisplay: data.printDisplay,
        type: data.type,
        qty: data.qty,
        description: data.description,
        discountList: data.discountList
      }
    };

    documentClient.put(params, function(err, data) {
      if (err) {
        console.log(err);
      } else {
        cb();
        dispatch(fetchPromotionList());
      }
    });
  };
}
export function editPromotion(hqId, uid, data, cb) {
  return (dispatch, getState) => {
    let params = {
      TableName: "Promotions",
      Key: {
        hqId,
        uid
      },
      UpdateExpression:
        "set #name = :name,#type = :type, uidHolder = :uidHolder, printDisplay = :printDisplay, qty = :qty, description = :description, discountList = :discountList",
      ExpressionAttributeNames: {
        "#name": "name",
        "#type": "type"
      },
      ExpressionAttributeValues: {
        ":name": data.name,
        ":type": data.type,
        ":uidHolder": data.uidHolder,
        ":printDisplay": data.printDisplay,
        ":qty": data.qty,
        ":description": data.description,
        ":discountList": data.discountList
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        cb();
        dispatch(fetchPromotionList());
      }
    });
  };
}
export function fetchPromotionList() {
  return (dispatch, getState) => {
    const { user, shop } = getState();
    let shopId = user.loggedIn.shopId;
    const link = shop.detail.link;

    if (link && link.inventory) {
      shopId = link.inventory;
    }

    let param = {
      TableName: "Promotions",
      KeyConditionExpression: "hqId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": shopId
      }
    };
    fetch(param, data => {
      dispatch({
        type: type.FETCH_PROMOTION_LIST,
        payload: (data && _.orderBy(data.Items, "row", "asc")) || []
      });
    });
  };
}
export const reorderPromotionList = (items, callBack) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let index = 1;
    async.eachSeries(
      items,
      (item, cb) => {
        let params = {
          TableName: "Promotions",
          Key: {
            hqId: item.hqId,
            uid: item.uid
          },
          UpdateExpression: "set #row = :newRow",
          ExpressionAttributeNames: { "#row": "row" },
          ExpressionAttributeValues: {
            ":newRow": index
          }
        };
        documentClient.update(params, function(err, data) {
          if (err) {
            // alert(err)
            console.log(err);
          } else {
            index++;
            cb();
          }
        });
      },
      err => {
        if (err) {
          alert(err);
        } else {
          dispatch(fetchPromotionList());
          if (callBack !== undefined) {
            callBack();
          }
        }
      }
    );
  };
};

const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const deletePromotion = (promotion, cb) => {
  return (dispatch, getState) => {
    let params = {
      TableName: "Promotions",
      Key: {
        hqId: promotion.hqId,
        uid: promotion.uid
      }
    };
    documentClient.delete(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        cb();

        dispatch(fetchPromotionList());
      }
    });
  };
};
