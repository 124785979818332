import React, { Component } from "react";
import _ from "lodash";
import { Route, Switch } from "react-router-dom";
import PromotionManage from "./PromotionManage";
import PromotionList from "./PromotionList";

class PromotionRetail extends Component {
  render() {
    let { match } = this.props;
    return (
      <div className="main-content container-fluid">
        <Switch>
          <Route
            path={`${match.url}/add`}
            render={props => <PromotionManage {...props} />}
          />
          <Route
            path={`${match.url}/edit/:hqId/:promotionUid`}
            render={props => <PromotionManage {...props} />}
          />
          <Route
            path={`${match.url}`}
            render={props => <PromotionList {...props} />}
          />
        </Switch>
      </div>
    );
  }
}

export default PromotionRetail;
