
import * as type from '../../types/shop'

const reducer = (state = {
    inventory: {}
}, action) => {
    switch (action.type) {
        case type.FETCH_SHOP_LIST:
            return action.payload
        default:
            return state
    }
}

export default reducer