
import * as type from '../../types/cart'
import _ from 'lodash'

const reducer = (state = {}, action) => {
    switch (action.type) {
        case type.TYPING_PRODUCT_QTY:
            return { ...state, [action.payload.itemUid]: action.payload.qty }
        case type.CART_DELETE_PRODUCT:
            let newState = _.cloneDeep(state)
            if (newState[action.payload.itemUid] !== undefined) {
                delete newState[action.payload.itemUid]
            }
            return { ...newState }
        case type.INSERT_ITEM:
            return action.payload
        case type.CLEAR_CART:
            return {}
        case type.CLEAR_CART_ONLY_ITEMS:
            return {}
        default:
            return state
    }
}

export default reducer