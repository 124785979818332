
import * as type from '../../types/cart'
import _ from 'lodash'
const initData = {
    isNotClearCart: false
}
const reducer = (state = initData, action) => {
    switch (action.type) {
        case type.DONT_CLEAR_CART_NEXT_TIME:
            return { ...state, isNotClearCart: action.payload }
        default:
            return state
    }
}

export default reducer