import _ from "lodash";
import * as type from '../types/history'


export const setHistoryTab = (mode) => {
    return (dispatch, getState) => {

        dispatch({
            type: type.SET_HISTORY_TAB,
            payload: mode
        });
    };
};

export const setHistoryBranch = (branch) => {
    return (dispatch, getState) => {

        dispatch({
            type: type.SET_HISTORY_BRANCH,
            payload: branch
        });
    };
};

export const clearHistoryBranch = () => {

    return (dispatch, getState) => {
        dispatch({
            type: type.CLEAR_HISTORY_BRANCH
        })
    }
}