import React from 'react'

const getStatusColor = (status) => {
    let color = 'secondary'
    switch (status.toString()) {
        case '200':
            color = 'blue'
            break;
        case '600':
            color = 'green'
            break;
        case '900':
            color = 'red'
            break;
        case '300':
            color = 'orange'
            break;
        default:
            break;
    }

    return color
}

export default getStatusColor