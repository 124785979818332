import { createSelector } from "reselect";
import _ from "lodash";

const linkedList = state => state.shop.linked


export const isLinkedSelector = createSelector(
    [linkedList],
    (linkedList) => {
        let isLinked = false
        _.forEach(linkedList, link => {
            if (link) {
                isLinked = true
            }
        })
        return isLinked
    })