
import { combineReducers } from 'redux'
import detail from './shopDetailReducer'
import list from './shopListReducer'
import linked from './linkedReducer'
import hqDetail from './shopHqDetailReducer'



export default combineReducers({
    detail,
    hqDetail,
    list,
    linked
})
