
import * as type from '../../types/history'

const reducer = (state = {
    tab: 'pending',
    selectedBranch: { value: null, label: 'เลือกสาขา' }
}, action) => {
    switch (action.type) {
        case type.SET_HISTORY_TAB:
            return { ...state, tab: action.payload }
        case type.SET_HISTORY_BRANCH:
            return { ...state, selectedBranch: action.payload }
        case type.CLEAR_HISTORY_BRANCH:
            return { ...state, selectedBranch: { value: null, label: 'เลือกสาขา' } }

        default:
            return state
    }
}

export default reducer