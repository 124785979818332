
import * as type from '../../types/cart'
import _ from 'lodash'
const initData = {
    failList: []
}
const reducer = (state = initData, action) => {
    switch (action.type) {
        case type.SET_FAIL_LIST:
            return { ...state, failList: action.payload }
        // case type.SET_FAIL_LIST:
        //     return initData
        default:
            return state
    }
}

export default reducer