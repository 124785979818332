
import * as type from '../../types/cart'
import _ from 'lodash'
const initData = {
    destination: null
}
const reducer = (state = initData, action) => {
    switch (action.type) {
        case type.SET_DESTINATION_BRANCH:
            return { ...state, destination: action.payload }
        case type.CLEAR_CART:
            return initData
        default:
            return state
    }
}

export default reducer