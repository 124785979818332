const province_th_eng = [
    'Krabi',
    'Bangkok',
    'Kanchanaburi',
    'Kalasin',
    'Kamphaengphet',
    'Khonkaen',
    'Chanthaburi',
    'Chachoengsao',
    'Chonburi',
    'Chainat',
    'Chaiyaphum',
    'Chumphon',
    'Chiangrai',
    'Chiangmai',
    'Trang',
    'Trat',
    'Tak',
    'Nakhonnayok',
    'Nakhonpathom',
    'Nakhonphanom',
    'Nakhonratchasima',
    'Nakhonsithammarat',
    'Nakhonsawan',
    'Nonthaburi',
    'Narathiwat',
    'Nan',
    'Buriram',
    'Pathumthani',
    'Prachuapkhirikhan',
    'Prachinburi',
    'Pattani',
    'Ayutthaya',
    'Phayao',
    'Phangnga',
    'Phatthalung',
    'Phichit',
    'Phitsanulok',
    'Phetchaburi',
    'Phetchabun',
    'Phrae',
    'Phuket',
    'Mahasarakham',
    'Mukdahan',
    'Maehongson',
    'Yasothon',
    'Yala',
    'Roiet',
    'Ranong',
    'Rayong',
    'Ratchaburi',
    'Lopburi',
    'Loei',
    'Lampang',
    'Lamphun',
    'Sisaket',
    'Sakonnakhon',
    'Songkhla',
    'Satun',
    'Samutprakan',
    'Samutsongkhram',
    'Samutsakhon',
    'Sakaeo',
    'Saraburi',
    'Singburi',
    'Sukhothai',
    'Suphanburi',
    'Suratthani',
    'Surin',
    'Nongkhai',
    'Nongbualamphu',
    'Angthong',
    'Amnatcharoen',
    'Udonthani',
    'Uttaradit',
    'Uthaithani',
    'Ubonratchathani',
    'Betong'
]

export default province_th_eng