
import { combineReducers } from 'redux'
import loggedIn from './loggedInReducer'
import userList from './userListReducer'


export default combineReducers({
    loggedIn,
    userList
})
