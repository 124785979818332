//[
//  { "name": "PrintTitle", "value": "Test print title ทดสอบพิมพ์หัวเรื่อง" },
//  { "name": "Line feed", "value": "The following line will be feed to new line" },
//  { "name": "LineFeed" },
//  { "name": "Print line", "value": "Print solid line after this" },
//  { "name": "PrintLine" },
//  { "name": "Long value", "value": "Test printing long value for checking word wrapping" },
//  { "name": "ภาษาไทย", "value": "ทดสอบการตัดคำภาษาไทยสำหรับประโยคยาวๆ" },
//  { "name": "Double line", "value": "Print double line after this" },
//  { "name": "PrintDoubleLine" },
//  { "name": "PrintComment", "value": "Test comment name values and word wrapping" },
//  { "name": "PrintComment", "value": "ทดสอบการใส่หมายเหตุเป็นภาษาไทย" }
import _ from "lodash";
import moment from "moment";
import ThermalService from "./thermalService";


// ต้อง discpatch
const printerMiddleware = store => next => {
  let printerTharmalService = new ThermalService();
  printerTharmalService.setStore(store);

  return function(action) {
    // มี obj meta = {   type = "" , printType =""  }
    if (action.meta && action.meta.type === "PRINT") {
      let { meta, payload } = action;

      console.log("PRINT LOG")
      console.log(payload);
      
      let { printType } = meta;
      switch (printType) {
        case "PREVIEW": // done
          printerTharmalService.handlePrintPreview(action);
          break;

        case "RECEIPT_FULL":
          printerTharmalService.handlePrintReceiptFull(payload);
          break;

        case "RECEIPT_PREVIEW":
          printerTharmalService.handlePrintReceiptPreview(payload);
          break;

        case "PRINT_JOB_SUMMARY": //done
          printerTharmalService.handlePrintJobSummary(payload);
          break;

        case "PAID": //done
          printerTharmalService.handlePaid(action);
          break;

        case "MAKE_SOUND": //done
          printerTharmalService.handleMakeSound(payload);
          break;

        case "OPEN_DRAWER": //done
          printerTharmalService.handleOpenDrawer(payload);
          break;

        case "VOID_ITEMS":
          printerTharmalService.handlePrintPreview(action);
          break;

        case "MOVE":
          printerTharmalService.handlePrintPreview(action);
          break;

        case "CUSTOM":
          printerTharmalService.handlePrintCustom(payload);
          break;
        case "PRINT_CUSTOM_V2":
          printerTharmalService.handlePrintCustomV2(payload);
          break;
        default:
          break;
      }
    }

    return next(action);
  };
};

export default printerMiddleware;
