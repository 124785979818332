
import { combineReducers } from 'redux'
import items from '../cart/cartItemsReducer'
import cartHqInfo from '../cart/cartHqInfoReducer'
import cartMetaData from '../cart/cartMetaDataReducer'
import cartValidate from '../cart/cartValidateReducer'


export default combineReducers({
    items,
    cartHqInfo,
    cartMetaData,
    cartValidate
})
