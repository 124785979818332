
import * as type from '../../types/loading'

const reducer = (state = {
    initLoad: true
}, action) => {
    switch (action.type) {
        case type.SET_INIT_LOADING:
            return { ...state, initLoad: action.payload }
        default:
            return state
    }
}

export default reducer