import React, { Component } from 'react'
import InventoryGroupList from '../inventory/InventoryGroupList';
// import InventoryItemList from './InventoryItemList';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as inventoryGroupActions from '../../shared/actions/inventoryGroupActions'
import ProductPicker from './ProductPicker';
import * as linkedSelector from '../../shared/selector/linkedSelector'

class Step1BuyProduct extends Component {

    state = {
        pickedTypeUid: 'all',
        pickedGroupUid: 'none',
        forceRender: false,
        filteredGroups: []

    }
    componentDidMount = () => {
        const { pickedTypeUid } = this.state
        this.filterGroupByType(pickedTypeUid, this.props.groups)
    }

    componentWillReceiveProps(nextProps) {
        const { pickedTypeUid } = this.state
        this.filterGroupByType(pickedTypeUid, nextProps.groups)
    }

    handleClickGroup = (pickedGroupUid) => {
        this.setState({
            pickedGroupUid
        })
    }

    sortGroupByType = () => {
        const { types, groups } = this.props
        let groupArr = []
        _.forEach([...types, { name: 'Other', uid: 'other', row: 99999999 }], type => {
            let filteredGroups = _.filter(groups, group => group.type === type.uid)

            if (_.size(filteredGroups) > 0) {
                groupArr = [...groupArr, ...filteredGroups]
            }
        })
        return groupArr
    }

    filterGroupByType = (pickedTypeUid, forceGroups) => {
        let groups = forceGroups || this.props.groups
        let filteredGroups = []
        if (pickedTypeUid !== 'all') {
            filteredGroups = _.filter(groups, group => group.type === pickedTypeUid)
        } else {
            filteredGroups = this.sortGroupByType(groups)
        }
        this.setState({
            filteredGroups,
            forceRender: true
        })
    }

    handleClickType = (pickedTypeUid) => {
        this.filterGroupByType(pickedTypeUid)
        this.setState({
            pickedTypeUid,
            pickedGroupUid: 'none',
            pickedItemsUid: {},
            forceRender: true
        })
    }

    setForceRender = (mode) => {
        this.setState({
            forceRender: mode
        })
    }

    getProductPicker = () => {
        const { handleClickNextStep, isLinked, cartHqInfo, isEdit } = this.props
        let productPicker = (
            <div class='main-content'>

                <ProductPicker
                    isEdit={isEdit}
                    handleClickNextStep={handleClickNextStep}
                />
            </div>
        )



        if (!isLinked && cartHqInfo.destination === null) {
            return (
                <div class='main-content'>
                    <center><h2>กรุณาเลือกสาขา</h2></center>
                </div>
            )
        }
        else {
            return productPicker
        }

    }

    render() {
        let { pickedGroupUid, pickedTypeUid, filteredGroups } = this.state
        let { history, match, hasOtherType, isEdit, } = this.props


        return (
            [
                // Page aside
                <InventoryGroupList
                    enableTopSpace={true}
                    readOnly={true}
                    handleClickGroup={(groupUid) => {
                        this.handleClickGroup(groupUid)
                        this.setForceRender(true)
                        window.$('html, body').animate({
                            scrollTop: window.$("#" + groupUid).offset().top - 109
                        }, 200);
                    }}
                    handleClickType={(typeUid) => {
                        this.handleClickType(typeUid)
                    }}
                    pickedTypeUid={pickedTypeUid}
                    pickedGroupUid={pickedGroupUid}
                    groups={filteredGroups}
                    history={history} match={match}
                    hasOtherType={hasOtherType}
                    isEditInvoice={isEdit}
                />
                ,
                this.getProductPicker()
            ]
        )
    }
}

const mapStateToProps = (state) => {
    let hasOtherType = false
    _.forEach(state.inventory.groups, group => {
        if (group.type === 'other') {
            hasOtherType = true
        }
    })




    return {
        hasOtherType,
        groups: state.inventory.groups,
        types: state.inventory.types,
        timestamp: state.inventory.timestamp,
        cartHqInfo: state.cart.cartHqInfo,
        isLinked: linkedSelector.isLinkedSelector(state),


    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...inventoryGroupActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Step1BuyProduct)