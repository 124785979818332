import _ from 'lodash'
// import $ from "jquery";
// import moment from 'moment'
// import { getTableInstance } from '../pages/pos/selector/posSelector';


export default function name(params) {

    var uuidPrinterHolder = {},
        computedPrinter = false;


    // use below this section (to v2)
    var baseUrl = "http://localhost:9000/api",
        printPreviewUrl = baseUrl + "/command/printPreview",
        printKitchenUrl = baseUrl + "/command/printKitchen",
        printVoidUrl = baseUrl + "/command/printVoid",
        boardcastItemUrl = baseUrl + "/command/boardcastItem",
        boardcastItemPrintUrl = baseUrl + "/command/boardcastItemPrint",
        openDrawerUrl = baseUrl + "/command/openDrawer",
        printCustomUrl = baseUrl + "/command/printcustom",
        printPayment = baseUrl + "/command/printPayment",
        checkSecondCashierUrl = baseUrl + "/setting/IsSecondCashier",
        printUrl = "",
        runMode = 1,
        savedZoneConverted,
        is2NdCashier = false,
        shopId = "3180",
        enableCustomerMode = false,
        hideRunningNumber = false,
        printOrderId = true, // receipt id when make payment
        itemIdHolder = {},
        alreadyInit = false,
        isShowPrice = false,
        subItemIdHolder = {};

    var printReceiptFullUrl = baseUrl + "/commandV2/printReceiptFull",
        printReceiptPreviewUrl = baseUrl + "/commandV2/printReceiptPreview",
        printJobSummaryUrl = baseUrl + "/commandV2/printJobSummary",
        openDrawerV2Url = baseUrl + '/commandV2/openDrawer',
        makeSoundUrl = baseUrl + '/commandV2/makeSound',

        printCustomV2Url = baseUrl + '/commandV2/PrintCustom';

    // this do retry 
    var store = null;
    return {
      
        handlePrintJobSummary,  //=
        handlePrintCustom,  //==
        handlePrintReceiptFull,  //=
        handlePrintReceiptPreview,  //=
        handlePrintCustomV2,
        handleMakeSound,
        handleOpenDrawer,
        setStore(_store) {
            store = _store


        }
    }

    function print(json, url, retry, forcePrintOneTime) {
        // if (retry > 3) {
        //     return;
        // }

        // var timeout = url.indexOf('Preview') > 0 ? 2000 : 2000;

        window.postMessage({
            type: "NICELOOP_APP_PRINTER",
            text: 'hello from web page',
            payload: {
                data: json,
                url: url
            }
        }, "*")
    }

    function handlePrintJobSummary(payload){

        var json = JSON.stringify(payload);

        print(json, printJobSummaryUrl)
    }

    function handlePrintReceiptFull(payload) {

        var json = JSON.stringify(payload);

        print(json, printReceiptFullUrl)
    }

    function handlePrintReceiptPreview(payload) {
        var json = JSON.stringify(payload);

        print(json, printReceiptPreviewUrl)
    }

    function printHttp(json, url, retry, forcePrintOneTime) {

        if (retry > 3) {
            return;
        }

        var timeout = url.indexOf('Preview') > 0 ? 2000 : 2000;


        window.$.ajax({
            type: 'post',
            url: url,
            data: json,
            timeout: timeout,
            dataType: 'json',
            //    Connection: 'close',

            contentType: "application/json; charset=utf-8",

        }).done(function (res) {


        }).fail(function (res) {

            if (forcePrintOneTime) {
                return;
            }
            var re = retry || 1;
            print(json, url, re + 1);
        });



    }

    function handleOpenDrawer(payload){

        var json = JSON.stringify(payload);

        print(json, openDrawerV2Url)
    }
 
    function handleMakeSound(payload){

        var json = JSON.stringify(payload);

        print(json, makeSoundUrl)
    }


    function handleVoid() {

       
    }

    function handlePrintCustom(arrayOfObjects) {

        ;

        var json = JSON.stringify(arrayOfObjects);
        print(json, printCustomUrl);
        // $.ajax({
        //     type: 'post',
        //     url: summaryInfoUrl,
        //     data: json,
        //     timeout: 30000,
        //     dataType: 'json',
        //     contentType: "application/json; charset=utf-8",
        //     xhrFields: {
        //         withCredentials: true
        //     }
        // });
    }

    function handlePrintCustomV2(payload){
        var json = JSON.stringify(payload)
        print(json,printCustomV2Url)
    }
    function handlePrintSummary(arrayOfObjects) {

        // var json = ko.toJSON(arrayOfObjects);
        // print(json, printCustomUrl, undefined, true);
    }



    function handleMoveTable(currentInstance, newTableNumber, checkedList, isAll) {

    }



    //....private

    // function _getDateString(datetime) {
    //     let string,
    //         mode = 0; // store.getState();


    //     if (mode === 1) {
    //         //info
    //         //  ซ่อนเวลา

    //         string = moment(datetime).format("DD/MM/YYYY");

    //     } else if (mode === 2) {
    //         //info
    //         string = " ";

    //     } else {
    //         string = moment(datetime).format("DD/MM/YYYY  HH:mm:ss");
    //     }

    //     return string
    // }

    // function _genReceipt(tableNumber, paymentState) {



    //     let { running = {}, jobs, info } = store.getState()
    //     let tableInstance =    getTableInstance(tableNumber,store.getState() ) //  posService.getTable(tableNumber, info, jobs)

    //     var Order = {};

    //     //OrderItems

    //     Order.OrderItems = _genOrderItems(tableInstance.items)

    //     //from other state
    //     Order.OrderId = 99999
    //     Order.ShiftWork = running.ShiftWork
    //     Order.UniqueId = _.random(999999999);


    //     //** from compute  
    //     Order.Table = tableInstance.table;
    //     Order.CustomerCount = tableInstance.guest
    //     Order.DateString = _getDateString(moment().format())
    //     Order.SubTotal = tableInstance.subTotal
    //     Order.ItemDiscount = tableInstance.itemsDiscount
    //     Order.Vat = tableInstance.vat
    //     Order.AddOn = tableInstance.addOn
    //     Order.Rounding = tableInstance.rounding
    //     Order.GrandTotal = tableInstance.grandTotal


    //     // from payment detail
    //     if (paymentState) {
    //         //Order.PaymentMethod = payment.creditCard() ? 1 : 0;
    //         Order.Received = paymentState.received
    //         Order.Change = paymentState.change;
    //         Order.Note = paymentState.note

    //     } else {
    //         // Order.Received = 0;
    //         // Order.Change = 0;

    //     }



    //     //header
    //     Order.CustomHeaders1 = []
    //     Order.CustomHeaders2 = []
    //     Order.CustomHeaders3 = []

    //     //custom 
    //     Order.CustomFooters1 = []
    //     Order.CustomFooters2 = []
    //     Order.CustomFooters3 = []



    //     return Order;
    // }

    // function _genOrderItems(items) {
    //     let array = []

    //     _.forEach(items, (e) => {
    //         array.push({
    //             ...e,
    //             quantity: e.qty
    //         })
    //     });


    //     return array
    // }

    // function _getDataFromObj(key, data) {
    //     try {
    //         return data[key]
    //     } catch (e) {
    //         return "missing data : " + key;
    //     }
    // }

}