import React, { Component } from 'react'
import _ from 'lodash'
import ProductSummaryTable from '../../shared/components/ProductSummaryTable'
import ProductValidate from './ProductValidate'

class ProductSummary extends Component {

    render() {
        let { products } = this.props

        return (
            <div>
                <div className="card">
                    <div className="card-body" style={{ padding: 0 }}>
                        <div style={{ padding: 20 }} >
                            <ProductSummaryTable products={products} title='สินค้าที่เลือก' />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default ProductSummary