import React from 'react'
import UserList from './UserList'
class ManageUser extends React.Component {

    state = {
        modalVisible: false
    }

    render() {
        const { modalVisible } = this.state
        return (
            <div className="main-content container-fluid">
                <UserList />
            </div>

        );
    }
}

export default ManageUser;