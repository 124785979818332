import _ from "lodash";
// import documentClient from "../awsAPIs/dynamoDb";
// import moment from "moment";
import * as type from '../types/cart'
// import * as linkedSelector from '../selector/linkedSelector'
// import swal from 'sweetalert'


export const setDestinationBranch = (branchUid) => {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_DESTINATION_BRANCH,
            payload: branchUid
        });
    };
};
