import React, { Component } from 'react'

class LeftAsideContainer extends Component {

    render() {
        let { children, enableTopSpace } = this.props
        return (
            <aside className="page-aside" >
                <div className="be-scroller-aside ps" >
                    <div className="aside-content">
                        <div className="content" style={{ padding: 17 }}>
                            {children}
                        </div>
                    </div>
                </div>
            </aside>
        )
    }
}

export default (LeftAsideContainer)