
import * as type from '../../types/history'
import _ from 'lodash'

let initData = {
    LastEvaluatedKey: null,
    currentPage: 0,
    transactions: {},
}

const reducer = (state = initData, action) => {
    switch (action.type) {
        case type.FETCH_PURCHASE_HISTORY:
            let { transactions } = state

            let newTx = _.cloneDeep(transactions)
            let newItemList = action.payload.Items
            if (newItemList.length < 1) { //ไม่ไปหน้าต่อไป
                return { ...state, LastEvaluatedKey: undefined }
            }
            let page = action.payload.currentPage
            if (page) { //CHECK FIRST FETCH
                newTx[page] = newItemList
            } else {
                newTx[0] = newItemList
            }

            return {
                LastEvaluatedKey: action.payload.LastEvaluatedKey,
                currentPage: page,
                transactions: newTx
            }
        case type.SWITCH_PAGE_PURCHASE_HISTORY: //ใช้ข้อมูลเดิม ไม่ fetch
            return { ...state, currentPage: action.payload.currentPage }
        case type.FAKE_UPDATE_TRANSACTION: //ใช้ข้อมูลเดิม ไม่ fetch
            return { ...state, transactions: action.payload }
        case type.CLEAR_PURCHASE_HISTORY:
            return initData
        default:
            return state
    }
}

export default reducer