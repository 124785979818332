
import { combineReducers } from 'redux'
import purchaseComplete from './purchaseHistoryCompleteReducer'
import purchasePending from './purchaseHistoryPendingReducer'
import historyPage from './historyPageReducer'
import purchaseSearch from './purchaseHistorySearchReducer'

export default combineReducers({
    purchaseComplete,
    purchasePending,
    historyPage,
    purchaseSearch
})
