import axios from "axios";
import moment from "moment";
const DOWNLOAD_INVOICE_URL =
  "https://0hhnefhyz8.execute-api.ap-southeast-1.amazonaws.com/dev/printInvoice";
const CHECK_STOCK_VALIDATE_URL =
  "https://e9gh6h5t7c.execute-api.ap-southeast-1.amazonaws.com/dev/stock/checkStockInvoice";
const CHECK_STOCK_BALANCE_URL =
  "https://e9gh6h5t7c.execute-api.ap-southeast-1.amazonaws.com/dev/stock/";
const GET_INVOICE_ID_URL =
  "https://qmx3n6pt2h.execute-api.ap-southeast-1.amazonaws.com/dev/next-id-format_increment-now/";
const CHECK_PRICE_URL =
  "https://e9gh6h5t7c.execute-api.ap-southeast-1.amazonaws.com/dev/items/checkPrice/";
const SEND_PRODUCT_EMAIL_REPROT_URL =
  "https://e9gh6h5t7c.execute-api.ap-southeast-1.amazonaws.com/dev/report/product-hq";
const createAxios = (url, data) => {
  return axios.post(url, getParams(data));
};

const getParams = data => {
  return { ...data };
};

const dowloadInvoice = data => {
  return createAxios(DOWNLOAD_INVOICE_URL, data);
};
const sendReportProductEmail = data => {
  return createAxios(SEND_PRODUCT_EMAIL_REPROT_URL, data);
};
const checkPrice = (data, hqId) => {
  return createAxios(CHECK_PRICE_URL + hqId, data);
};

const checkStock = data => {
  return createAxios(CHECK_STOCK_VALIDATE_URL, data);
};

const fetchPendingInventory = shopId => {
  return createAxiosGet(CHECK_STOCK_BALANCE_URL + shopId);
};

const getInvoiceId = hqId => {
  return createAxiosGet(
    GET_INVOICE_ID_URL + hqId + `/${moment().format("YYYY-MM")}`
  );
};
const createAxiosGet = url => {
  return axios.get(url);
};

export default {
  dowloadInvoice,
  checkStock,
  fetchPendingInventory,
  getInvoiceId,
  checkPrice,
  sendReportProductEmail
};
