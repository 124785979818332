import * as type from "../../types/promotion";
import PubSub from "pubsub-js";
const reducer = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PROMOTION_LIST:
      PubSub.publish("PROMOTION_LIST", action.payload);
      
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
