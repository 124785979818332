import * as type from '../../types/notification'
import moment from 'moment'

const reducer = (state = {
    lastUpdate: moment().toISOString()
}, action) => {
    switch (action.type) {
        case type.SET_LAST_NOTI_TIMESTAMP:
            return { ...state, lastUpdate: action.payload }
        default:
            return state
    }
}

export default reducer