const getStatusName = (status) => {
    let name = 'Edited';

    switch (status.toString()) {
        case '200':
            name = 'Pending';
            break;
        // case '400':
        //     name = 'Process';
        //     break;
        // case '500':
        //     name = 'Shipping';
        //     break;
        case '600':
            name = 'Confirmed';
            break;
        case '900':
            name = 'Void';
            break;
        case '300':
            name = 'Edited';
            break;
        default:
            break;
    }
    return name;
}

export default getStatusName