import React from "react";
import ProductList from "./ProductList";
import PromotionDetail from "./PromotionDetail";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as inventorySelector from "../../shared/selector/inventorySelector";
import _ from "lodash";
import * as promotionActions from "../../shared/actions/promotionActions";
import swal from "sweetalert";
import documentClient from "../../shared/awsAPIs/dynamoDb";

const initDiscount = {
  mode: 0,
  value: 0,
  amount: 0
};

const initData = {
  uidHolder: {},
  name: "",
  printDisplay: "",
  type: "qty",
  qty: 1,
  description: "",
  discountList: [initDiscount],

  mode: "add"
};

class PromotionManage extends React.Component {
  state = initData;

  componentDidMount = () => {
    const { match } = this.props;
    const { promotionUid, hqId } = match.params;
    if (promotionUid) {
      this.fetchPromotionDetail(hqId, promotionUid);
    }
  };

  fetchPromotionDetail = (hqId, uid) => {
    let param = {
      TableName: "Promotions",
      Key: {
        uid,
        hqId
      }
    };

    let self = this;

    documentClient.get(param, (err, data) => {
      if (err) {
        console.log(err);
        // alert(err)
      } else {
        self.setState({ ...data.Item, mode: "edit" });
      }
    });
  };

  handleOnchange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeType = type => {
    this.setState({ type });
  };
  handleChangeQty = newQty => {
    let qty = parseInt(newQty);
    let newDiscountList = _.cloneDeep(this.state.discountList);

    if (qty > this.state.qty) {
      // มากกว่า
      const plusLength = qty - newDiscountList.length;
      _.times(plusLength, i => {
        newDiscountList.push(initDiscount);
      });
    } else {
      // น้อยกว่า
      newDiscountList = newDiscountList.slice(0, qty);
    }
    this.setState({ qty, discountList: newDiscountList });
  };
  selectItem = (uid, mode) => {
    const { uidHolder } = this.state;
    let newSelectedItems = _.cloneDeep(uidHolder);
    if (newSelectedItems[uid]) {
      delete newSelectedItems[uid];
    } else {
      newSelectedItems[uid] = mode;
    }
    // console.log(JSON.stringify(newSelectedItems));
    this.setState({
      uidHolder: newSelectedItems
    });
  };
  setDicountLine = (data, index) => {
    let newDiscountList = _.cloneDeep(this.state.discountList);
    newDiscountList = [
      ...newDiscountList.slice(0, index),
      data,
      ...newDiscountList.slice(index + 1)
    ];
    this.setState({ discountList: newDiscountList });
  };
  validate = () => {
    let state = this.state;
    if (state.name === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกชื่อโปรโมชัน", "error");
      return true;
    }
    if (state.description === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกรายละเอียดโปรโมชัน", "error");
      return true;
    }
    if (state.printDisplay === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอก Print Display", "error");
      return true;
    }
    let foundFailDiscountLine = _.find(
      state.discountList,
      line => line.value === ""
    );
    if (foundFailDiscountLine) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกส่วนลดเป็นตัวเลข", "error");
      return true;
    }
    if (_.isEmpty(state.uidHolder)) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณาเลือกสินค้าที่คุณต้องการทำโปรโมชัน",
        "error"
      );
      return true;
    }
  };
  createPromotion = cb => {
    const { actions } = this.props;
    if (this.validate()) {
      return;
    }
    actions.addPromotion(this.state, () => {
      swal("สำเร็จ !", "เพิ่มโปรโมชันสำเร็จ", "success");
      cb();
    });
  };
  editPromotion = cb => {
    const { actions, match } = this.props;
    const { promotionUid, hqId } = match.params;
    if (this.validate()) {
      return;
    }

    actions.editPromotion(hqId, promotionUid, this.state, () => {
      swal("สำเร็จ !", "แก้ไขโปรโมชันสำเร็จ", "success");
      cb();
    });
  };

  render() {
    const { products, history } = this.props;
    const { uidHolder } = this.state;
    return (
      <div>
        <div className="row m-0">
          <div className="col-md-7 pl-0 pr-2">
            <h3>Promotion Detail</h3>
            <PromotionDetail {...this.state} {...this} history={history} />
          </div>
          <div className="col-md-5 pr-2 pl-2">
            <h3>Products</h3>
            <ProductList
              products={products}
              selectItem={this.selectItem}
              uidHolder={uidHolder}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: inventorySelector.typeGroupItemSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...promotionActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionManage);
