import React, { Component } from 'react'
import _ from 'lodash'

class ProductSummaryTable extends Component {

    getTypeColor = (type) => {
        let color = 'white'
        if (type === 'group') {
            color = 'lightgrey'
        }
        else if (type === 'type') {
            color = "#9dbdf2"
        }

        return color

    }

    render() {
        let { products, title } = this.props
        var itemRow = 0
        return (
            <div >
                {title && <span style={{ fontSize: 20 }}>{title}</span>}
                <table  style={{fontSize : 14 }} className="table table-striped table-hover  " >
                    {/* <thead>
                        <tr>
                            <th style={{   }}>Name</th>
                            <th style={{ }}>Qty</th>
                            <th class="text-right" style={{   }}>Unit Price</th>
                            <th class="text-right" style={{   }}>Total Price</th>
                        </tr>
                    </thead> */}
                    <tbody >
                        {_.map(products, (item, i) => {
                            if(item.type === 'type'){
                                return <TypeRow  key={item.uid} item={item} index={i}/>
                            }
                            if(item.type === 'group'){
                                return <GroupRow key={item.uid}  item={item} index={i}/>
                            }
                            itemRow++
                            return <ItemRow key={item.uid}  item={item} index={itemRow}/>

                            
                            
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}


export default ProductSummaryTable


function GroupRow({item}){
    return (
        <tr key={item.uid} style={{ backgroundColor: '',  }}>
            <th colSpan={1}  class="cell-detail" >
                <span style={{margin : 0}} >#{item.name}  </span>
                </th>

                <th>Qty</th>
                <th class="text-right">Price</th>
                <th class="text-right">Total</th>

            
        </tr>
    )
}

function ItemRow({item, index}){
    return (
        <tr key={item.uid} style={{   }}>
            <td   class="cell-detail">
            <div>
            <span style={{color : 'gray' , display : 'inline', marginLeft : 8, marginRight :4}}>    {index}.  </span>
                <span style={{  display : 'inline'}}>    {item.name}  </span>
            </div>
              
                </td>
             
                
                    <td class="cell-detail  " >
                    <strong> {item.qty}{'  '}{item.unit}</strong>
                   </td>
                    <td class="cell-detail text-right" ><span>{(item.price || '').toLocaleString() || ''}</span></td>
                    <td class="cell-detail  text-right" ><span>{(item.totalPrice || '').toLocaleString() || ''}</span></td>
                
            
        </tr>
    )
}

function TypeRow({item}){
    return (
        <tr key={item.uid} style={{ backgroundColor: '',   }}>
            <td colSpan={4} class="cell-detail">
                <h4 class="text-center" style={{margin : 0}}> =====    {item.name}   ===== </h4>
                </td>
             
        </tr>
    )
}