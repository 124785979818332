import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import swal from 'sweetalert'
import CreateUserForm from '../../pages/SignUp/CreateUserForm'

class BranchDetailModal extends React.Component {


    render() {
        const { match, selectedBranch, timestamp, fetchShop } = this.props

        return (
            <div class="modal" id="branchDetailModal" style={{ perspective: 'none' }} >
                <div class="modal-content" style={{ width: 400 }}>
                    <div class="modal-body form" style={{ padding: 10 }}>
                        <CreateUserForm isEdit={true} match={match} selectedBranch={selectedBranch} timestamp={timestamp} fetchShop={fetchShop} />
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // loggedInUser: state.user.loggedIn,
        // types: state.inventory.types,
    }
}

function mapDispatchToProps(dispatch) {
    // let actions = bindActionCreators({ ...inventoryGroupActions, ...inventoryTypeActions, ...cartHqActions }, dispatch)

    return {
        // actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchDetailModal)