import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as purchaseHistoryAction from '../actions/purchaseHistoryActions'
import swal from 'sweetalert'
import getStatusName from '../functions/getStatusName'
class ButtonInvoiceStatus extends Component {
    onClickChangeStatus = (status) => {
        const { tx, cbSuccess, cbSuccess2, actions } = this.props
        // actions.updateInvoiceStatus({ tx, status, cbSuccess, reason })

        let cancelParam = {
            title: `คุณต้องการยกเลิก Invoice เลขที่ \"${tx.invoiceId}\" ?`,
            text: "กรอกเหตุผล",
            icon: "warning",
            content: "input",
            buttons: ["Cancel", "Submit"],
            // buttons: true,
            dangerMode: true,
        }
        let confirmParam = {
            title: `คุณต้องการยืนยัน Invoice เลขที่ \"${tx.invoiceId}\" ?`,
            text: "กรุณาตรวจสอบข้อมูลก่อนการทำการยืนยัน หากทำการยืนยันแล้วจะไม่สามารถแก้ไขสินค้าได้",
            icon: "warning",
            buttons: ["Cancel", "Confirm"],
        }

        // let statusParam = {
        //     title: `กรอกเหตุผล`,
        //     icon: "warning",
        //     content: "input",
        //     buttons: ["Cancel", "Submit"],
        // }

        // if (status !== '900') {
        //     actions.updateInvoiceStatus({ tx, status, cbSuccess, cbSuccess2 })
        //     return
        // }

        let param;
        if (status === '600') {
            param = confirmParam
            swal(param)
                .then((reason) => {
                    if (reason) {
                        actions.updateInvoiceStatus({ tx, status, cbSuccess, cbSuccess2 })
                    }
                });
            return
        }
        if (status === '900') {
            param = cancelParam
            swal(param)
                .then((reason) => {
                    if (reason) {
                        actions.updateInvoiceStatus({ tx, status, cbSuccess, reason, cbSuccess2 })
                    }
                });
        }

        // else if (status === '400') {
        //     param = statusParam
        // }



    }
    render() {
        const { tx, topSpace, cancelButton, isAction } = this.props
        if (cancelButton) {
            return (
                <button style={{ marginTop: 15, color: 'red' }}
                    class="btn btn-space btn-secondary hover"
                    onClick={() => {
                        this.onClickChangeStatus('900')
                    }}
                >Void</button>
            )
        }
        return (
            <div className="btn-group btn-space" style={{ margin: 0, top: topSpace ? topSpace : 0 }}>
                {
                    isAction ?
                        <button className="btn btn-primary" type="button" >Actions</button> :
                        <button className="btn btn-primary" type="button" style={{ width: 85 }}>
                            {getStatusName((tx.status).toString())}</button>
                }

                <button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false"><span className="mdi mdi-chevron-down"></span><span className="sr-only">Toggle Dropdown</span></button>
                <div className="dropdown-menu" role="menu" x-placement="bottom-start" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(67px, 30px, 0px)' }}>
                    {/* <a className="dropdown-item" onClick={() => this.onClickChangeStatus('200')} >{getStatusName('200')}</a> */}
                    {/* <a className="dropdown-item" onClick={() => this.onClickChangeStatus('300')} >{getStatusName('300')}</a> */}
                    {/* <a className="dropdown-item" onClick={() => this.onClickChangeStatus('400')} >{getStatusName('400')}</a> */}
                    {/* <a className="dropdown-item" onClick={() => this.onClickChangeStatus('500')} >{getStatusName('500')}</a>  */}
                    <a className="dropdown-item" onClick={() => this.onClickChangeStatus('600')} >{getStatusName('600')}</a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...purchaseHistoryAction }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonInvoiceStatus)