import PubSub from "pubsub-js";

import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import posCartReducer from "./reducer";

import { createStore, combineReducers, applyMiddleware } from "redux";

// const reduers = combineReducers(
//     posCartReducer
// )

let storeInit = createStore(
  posCartReducer,
  applyMiddleware(ReduxThunk, logger)
);

PubSub.subscribe("MENU_ITEMS", (topic, data) => {
  storeInit.dispatch({
    type: "MENU_ITEMS",
    payload: data
  });
});

PubSub.subscribe("INVENTORY_REDUCER", (topic, data) => {
  storeInit.dispatch({
    type: "INVENTORY_REDUCER",
    payload: data
  });
});

PubSub.subscribe("PROMOTION_LIST", (topic, data) => {
  storeInit.dispatch({
    type: "PROMOTION_LIST",
    payload: data
  });
});
export default storeInit;
