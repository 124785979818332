export const TYPING_PRODUCT_QTY = "TYPING_PRODUCT_QTY"
export const CART_DELETE_PRODUCT = "CART_DELETE_PRODUCT"
export const CLEAR_CART = "CLEAR_CART"
export const CLEAR_CART_ONLY_ITEMS = "CLEAR_CART_ONLY_ITEMS"
export const DONT_CLEAR_CART_NEXT_TIME = "DONT_CLEAR_CART_NEXT_TIME"
export const INSERT_ITEM = "INSERT_ITEM"

export const SET_DESTINATION_BRANCH = "SET_DESTINATION_BRANCH"
export const HQ_INIT_EDIT_INSERT_BRANCH = "HQ_INIT_EDIT_INSERT_BRANCH"

export const SET_FAIL_LIST = "SET_FAIL_LIST"
 
