

import React, { useState } from 'react';

function ProductList() {
const [count, setCount] = useState(0);
return (
<div>
<h1>Product  List</h1>
</div>
);
}

export default ProductList